import React from "react";


const Purchasing = () => {
    return (
        <div id='purchasing-container' className="container">

            <div className="row">
                <h3 className="resource-title">ROADMAP: Purchasing a Dollar General</h3>

            </div>

            <div className='row'>
                <h4>Consider Financing Options</h4>
                <div className="col-lg-6 due-dilligence-parent2">

                    <div className="due-dilligence2">

                        <div className="col-title">
                            <h5>Cash</h5>
                        </div>

                        <h6>
                            Pros:
                        </h6>

                        <ul>
                            <li>Faster process - no loan approval times or bank-specific bureaucy, can close immediately</li>
                            <li>No interest payments</li>
                            <li>Less risky to seller</li>

                        </ul>


                        <h6>Cons:</h6>
                        <ul>
                            <li>Must do your own due dilligence without aid from a bank</li>
                            <li>Immediate financial obligation</li>
                            <li>Requires more investors to supplement cashflow</li>
                        </ul>

                    </div>
                </div>

                <div className="col-lg-6 due-dilligence-parent2">
                    <div className="due-dilligence2">

                        <div className="col-title">
                            <h5>Bank Financing:</h5>
                        </div>

                        <h6>
                            Pros:
                        </h6>

                        <ul>
                            <li>Not required to pay full amount up front</li>
                            <li>Banks will assist in due dilligence</li>

                        </ul>


                        <h6>Cons:</h6>
                        <ul>
                            <li>Lender-specific bureaucracy including loan approval time</li>
                            <li>Interest obligations</li>
                            <li>Banks may set insurance rates</li>
                        </ul>

                    </div>
                </div>

            </div>

            <div className="row broker-parent">
                <div className="col-12 broker-rep">
                    <h4>Broker Representation:</h4>
                    <ul>
                        <li>Do you have or need a <span className='bolder'>Broker</span> representing your best interests?</li>
                        <li>Seller or Seller’s Broker will likely provide a <span className='bolder'>Purchase and Sale Agreement</span>  if you haven’t prepared one of your own</li>
                    </ul>
                </div>
            </div>

            <div className="row title-parent">
                <div className="col-12 title-company">
                    <h4>Select a <span className="bolder">Commercial Title Company</span></h4>
                    <p>They can provide title search services, hold escrow funds and coordinate the closing process</p>
                </div>
            </div>

            <div className="row psa-parent">
                <div className="col-12 psa-paragraph">
                    <p>Once you have an executed <span className="bolder">Purchase and Sale Agreement</span> signed by both parties, you will want to begin collecting the following due diligence documents from the seller, along with any documents your lender is requiring (if applicable). These documents will help you better understand the financial aspects of being a Dollar General landlord – rent amounts, maintenance and repair responsibilities, tax and insurance costs, etc. :</p>
                </div>
            </div>

            <div className="row psa-list-parent">
                <div className="col-12 psa-list">
                    <ul>
                        <li><span className="bolder">Estoppel</span> (see “How to Read a Dollar General Estoppel” for more information) </li>
                        <li><span className="bolder">Original Lease and Lease Amendments</span></li>
                        <li ><span className="bolder">Survey</span></li>
                        <li><span className="bolder">Existing Title Insurance Policy</span></li>
                        <li><span className="bolder">Deed</span></li>
                        <li><span className="bolder">Property Tax information</span> from appropriate city/county/etc. that property is located in, including proof that Dollar General reimburses (if stated that tenant is responsible for reimbursement in the lease) </li>
                        <li><span className="bolder">Current Insurance information</span>, including proof that Dollar General reimburses (if stated that tenant is responsible for reimbursement in the lease)</li>
                        <li><span className="bolder">Maintenance and Repair contact list</span> including lawncare and snow removal, maintenance log, current warranties</li>
                    </ul>
                </div>
            </div>

        </div>
    )
};

export default Purchasing;