import React from "react";
import {Link} from 'react-router-dom';
import PageTitle from "./components/Pagetitle";


import Blogpost from "./components/Blogpost";
import Posts from "./Posts"
import './Blog.css'

let Postdivs = [];
let Posttitles = [];

for (let Post of Posts){
    
    
    const newDiv = <Blogpost id={Post.id} title={Post.title} date={Post.date} content={Post.content}/>
    Posttitles.push(<li className="sidebar-title-link"><Link to={`#`+Post.id}>{Post.title}</Link></li>)
    Postdivs.push(newDiv);



}






const Blog = () => {
    return(
        <div id='Blog'>
                        <PageTitle htext='DGLA Blog and Updates'/>

            <div className="container">
                <div className="row">

                    <div className="col-lg-8 blogcolumn">
                        {Postdivs}
                    </div>

                    <div className="col-lg-4 titlecolumn">
                        <h3>Archive</h3>
                        <ul>{Posttitles}</ul>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Blog;