import React from "react";
import Manual from './assets/manualstock.jpeg';

const LibAbout = () =>{
    return(
    <div id=''>
        <div className="container">
            <div className="row">
                <div className="bigger-p col-12">
                    <h3 className="resource-title">ABOUT</h3>
                    <p className="bigger-p lead"><span className="larger">The DGLA library is a collection of informational resources and guides created by our members, with the purpose of sharing knowledge and mentoring new DG owners in our organization. This library is being updated all the time, so remember to check back regularly! If you are interested in contributing to the resource library, or have any topics you would like to see covered, please contact us at <a>info@dglandlord.com</a></span></p>
                    <img className="img-fluid" src={Manual}/>
                </div>
            </div>
        </div>
    </div>
    )
}

export default LibAbout;