import React from "react";


import Pic from './images/dgla-logo.jpeg'


const Showhide = () =>{
    const curSection = document.getElementsByClassName('readmore')
    if (curSection.style.display == 'none'){
        curSection.style.display = 'block';
    }


}

const Blogpost = (props) => {



    return (
        <div id={props.id} className="Blogpost">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h2 className="blogtitle">{props.title}</h2>
                        <p className="date">{props.date}</p>
                        {props.content}
                        <br />
{/*                         <button onClick={Showhide}>
                        Read more
                        </button> */}
                        <hr />
                    </div>
                </div>
            </div>

        </div>
    )




}

export default Blogpost;