import React from "react";
import { NavLink } from "react-router-dom";
import './nav.css';





class MenuLink extends React.Component {
    render() {
        const fun = this.props.dest;
        const linktext = this.props.linktext;
        return (
            <li>
                <NavLink        
                    className={({ isActive }) => (isActive ? "link-active" : "link")} 
                    to={"/"+fun}>
                        
                    {linktext}
                    
                </NavLink>

            </li>

        );
    }
}

export default MenuLink;