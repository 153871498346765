import React from "react";

import './Footer.css';

import Logo from './images/logoinverted.png'

const Footer = () => {
  return (
    <div id='Footer'>
      <div className="container">
        <div className="row h-100 justify-content-center align-items-center" style={{textAlign: 'center'}}>

          <div className="col-md-4 footer-links">
            <h6><a href='https://www.linkedin.com/company/dglandlord/' target='_blank' >LinkedIn</a></h6>
            <h6><a href="https://www.facebook.com/profile.php?id=100087967343849" target='_blank'>Facebook</a></h6>
          </div>

          <div className="col-md-4">
          <img className='footer-logo' src={Logo} style={{width:'100%', maxWidth: '200px', margin: 'auto', padding:'10px'}}/>

          </div>

          <div className="col-md-4">
          <p>CONTACT US: <br/>(804)-665-0880<br/>
            team@dglandlord.com<br/>
            7430 Brook Rd<br/>
            Richmond, VA 23227</p>
          </div>

        </div>

{/*         <div className="row"  style={{display: 'flex', alignContent: 'center', textAlign: 'center'}}>

          <div className="col-sm-6">
            <img src={Logo} style={{width: '400px', margin: 'auto', padding: '0% 10%'}}/>
          </div>

          <div className="col-sm-6" style={{display: "flex", alignContent: 'center', justifyContent: 'center'}}>
            <p>CONTACT US: <br/>(804)-644-4924<br/>
            contact@dglandlord.com<br/>
            530 E. Main St, Suite 1000<br/>
            Richmond, VA 23219</p>
          </div>

        </div> */}


      </div>
    </div>
  )
}






export default Footer;
