import React, { useState, useRef } from "react";
import axios from "axios";

import IntakeForm from "./components/IntakeForm";

import './Contact.css';


const TestForm = () => {

    const [file, setFile] = useState()


    const handleChange = (event) =>{
        setFile(event.target.files[0])
    }

    const handleSubmit = (event) =>{
        event.preventDefault()
        const url = 'https://public.herotofu.com/v1/28367c70-b9fc-11ed-aecb-e7ff0a88dd7c';
        const formData = new FormData;
        formData.append("file", file);
        formData.append("filename", file.name);
        const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          axios.post(url, formData, config).then((response) => {
            console.log(response.data);
          });


    }

    return (
        <div id='testForm'>
                <hr></hr>
            <form onSubmit={handleSubmit}>
                

                <input
                    type='file'
                    onChange={handleChange}
                />


                <button type="submit">Senf!</button>



            </form>
            <hr/>
                        
        </div>
    )
}



const Intake = () => {
    return (

        <div id='Intake'>



            <IntakeForm />

        </div>

    )
}

export default Intake;