import React from "react";
import { NavLink } from "react-router-dom";

import "./Landing.css";

const Landing = () => {
    return (
        <div className="landing-parent">

            <div className='container' id='Landing'>

                <div className="row landing-body">
                    <div className="che">
                        <h4><span className='special-color'>Welcome!</span></h4>
                        <h4>We're excited you want to learn more about DGLA.</h4>

                        <div className="tagline">
                            <h3>We are committed to helping independent Dollar General property owners achieve improved financial results and reduced complexity of ownership as a Landlord.</h3>
                        </div>

                        {/*                     <h5>To start with, you can visit the <NavLink to='/Home' className='landing-links'>Home</NavLink>  page for an overview of our organization.
                    </h5>

                    <h5>If you are interested in becoming a member, please visit our <NavLink className='landing-links' to='./Contact'>Contact</NavLink> page</h5>

                    <h5>Announcing our first <span className="special-size">DGLA Webinar</span>! For more information, or to sign up, click <NavLink className='landing-links' to='/Webinar'>Here</NavLink></h5>
 */}
                    </div>
                </div>

                <div className="row landing-body">

                    <div className="col-md-4">
                        <div>                                        <h5>To start with, you can visit our Home page for an overview of our organization.
                        
                        </h5>
                        
                         

                        </div>

                        <NavLink to='/Home' className='landing-button-parent'><button className="landing-button">HOME</button></NavLink>

                    </div>

                    <div className="col-md-4">
                        <div>                    <h5>If you are interested in becoming a member, please visit our Contact page</h5>
                        </div>

                        <NavLink to='/Home' className='landing-button-parent'><button className="landing-button">CONTACT US</button></NavLink>

                    </div>

                    <div className="col-md-4">
                        <div>
                            <h5>Announcing our first <span className="special-size">DGLA Webinar</span>! Click the link below to learn more.</h5>

                            

                        </div>

                        <NavLink className='landing-button-parent' to='/Webinar'><button className="landing-button">WEBINAR</button></NavLink>

                    </div>


                </div>

                <div className="row">

                    <div className="col-12 slogan-row">
                        <hr/>
                        <h3>DGLA: <span className="extra-fat">Effective Solutions for Driven Landlords.</span></h3>
                        <hr/>
                    </div>

                </div>

            </div>
        </div>

    );
}

export default Landing;