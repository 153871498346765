import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    HashRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";

import App from './App';
import Home from './Home';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import Blog from './Blog';
import Intake from './Intake';
import Submission from './Submission';
import IntakeSubmission from './IntakeSubmission';
import Webinar from './Webinar';
import Landing from './Landing';
import Library from './components/library/Library';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
  <Routes>
  <Route path="/" element={<App />}>
      <Route path="/" element={<Navigate to="/Home" />} />
      <Route path='Home' element={<Home />} />
      <Route path='About' element={<About />} />
      <Route path='Blog' element={<Blog/>} />
      <Route path='Services' element={<Services />} />
      <Route path='Contact' element={<Contact />} />
      <Route path='Intake' element={<Intake />}/>
      <Route path='Submission' element={<Submission />}/>
      <Route path='IntakeSubmission' element={<IntakeSubmission />}/>
      <Route path='Webinar' element={<Webinar/>}/>
      <Route path='Landing' element={<Landing/>}/>
      <Route path='Library' element={<Library/>}/>
      <Route path="Forum" element={<Navigate to="/Forum" />} />




  </Route>

</Routes>
</HashRouter>

);


