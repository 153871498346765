import React from "react";
import ContactForm from "./components/ContactForm";
import PageTitle from "./components/Pagetitle";

import Handshake from './images/handshake.png';
import './Contact.css';

const Contact = () =>{
    return(
        <div id="#Contact">
        <PageTitle htext='Join DGLA'/>
        <div className="container">
            <div className="row">
                
                <div className="col-lg-6 contact-sidebar">

                    <h3>Interested in taking advantage of our member benefits?</h3>
                    <p>We appreciate your interest! Please provide your contact information and a representative will be in touch shortly to establish your membership.</p>

<div className="img-center">
                        <img src={Handshake} className='handshake-img'/>
                    </div>
                </div>

                <div className="col-lg-6">
                    <ContactForm/>
                </div>


            </div>
        </div>

        </div>
    )
}

export default Contact