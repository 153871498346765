const MaintenanceReimbursements = () => {
    return(
        <div className="container" id='MaintenanceReimbursements'>

            <div className="row">
                <h3 className="resource-title">Maintenance Reimbursements</h3>
            </div>

<div className="row"><div className="col-12">            <h5>Each <span className="bolder">Lease Agreement</span> establishes terms that dictate which maintenance items are the tenant’s responsibility, or the Landlord’s. Generally speaking, this information can be found in two sections of the lease; <span className="italic">“Maintenance”</span> and <span className="italic">“Fixtures and Equipment”</span>. Some of these maintenance items are reimbursable by the tenant, and this guide aims to show you how to determine which expenses fall under that category.  </h5></div></div>

            <div className="row broker-parent">
                <div className="col-12 broker-rep">
                    <h4 className="italic">Maintenance</h4>

                    <h5>Repair items that are <span className="bolder">exclusively the responsibility of the Landlord</span>, and usually contains the following language:</h5>

                    <h5 className="italic leasequote">“Lessor shall maintain at its cost and expense in good condition and shall perform all necessary maintenance, repair, and replacement to the exterior of the premises including, but not limited to, the roof, all paved areas, foundation, floors, walls, all interior and exterior utility lines and pipes, and all other structural portions of the building during the term of this Lease and any renewal periods.”</h5>
 
                    <h5>Any of the items listed must be completed and paid for entirely by the Landlord. They are <span className="bolder">not reimbursable</span>.  </h5>

                </div>
            </div>

            <div className="row broker-parent">
                <div className="col-12 broker-rep">
                    <h4 className="italic">Fixtures and Equipment</h4>

                    <h5>contains language establishing the “major/minor provisions” of the Lease, which is what enables us to determine <span className="bolder">what is considered reimbursable</span>. The language in this section is generally:</h5>

                    <h5 className="italic leasequote">“Lessor shall at all times furnish heating, lighting, plumbing, 
and air conditioning equipment in the premises and shall be responsible for the entire cost of major repairs and replacement of all such equipment. Lessee shall be responsible for the entire cost of minor repairs and routine maintenance. Minor repairs are defined as any repairs costing less than seven hundred fifty and 00/100 ($750.00) dollars per occurrence, and major repairs are defined as any repairs costing seven hundred fifty and 00/100 ($750.00) or more per occurrence.”</h5>

<h5>Any repairs on the heating, lighting, plumbing and HVAC equipment that fall under the cost of “minor repairs” are reimbursable by the tenant.</h5>

<hr></hr>
<span className="italic">EXAMPLE: let’s say Dollar General sent you a maintenance ticket requesting repairs to an HVAC unit. You send your vendor and they’re able to complete the repairs for $650. This would then be reimbursable because the total cost of repairs was under $750.  </span>

<hr></hr>

<h5>To submit that invoice for reimbursement, you must do the following: 
</h5>
<ol>
    <li><span className="non-bolded">Obtain a copy of the invoice with a detailed description of the work completed </span></li>
    <li><span className="non-bolded">Obtain a copy of the payment confirmation to vendor (copy of check, etc)</span></li>
    <li><span className="non-bolded">Send both documents to ll-lcinvoices@dollargeneral.com </span></li>

</ol>

<h5>After submitting the reimbursement request, someone from Dollar General will reach out to confirm whether the request was accepted or denied, and why.  </h5>
 

                </div>
            </div>

        </div>
    )
}

export default MaintenanceReimbursements;