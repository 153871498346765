import React, {useState} from "react";

const FORM_ENDPOINT = "https://public.herotofu.com/v1/52b1a7d0-0593-11ee-8267-d3eb100789b4"

const WebinarForm = () => {
    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = () => {
        setTimeout(() => {
          setSubmitted(true);
        }, 100);
      };

      if (submitted) {
        return (
          <>
            <h2>Thank you!</h2>
            <div>We'll be in touch soon.</div>
          </>
        );
      }

      return (
        <div class="container">
  <div class="row mx-0 justify-content-center">
    <div class="col-12">
      <form
        method="POST"
        class="w-100 p-4"
        action={FORM_ENDPOINT}
      >
        <label class="d-block mb-4">
          <span class="form-label d-block">Your name</span>
          <input
            name="firstname"
            type="text"
            class="form-control"
            placeholder="First"
          />

<input
            name="lastname"
            type="text"
            class="form-control"
            placeholder="Last"
          />
        </label>

        <label className='d-block mb-4'>
          <span className="form-label d-block">Email</span>
          <input 
            name='email'
            type="email"
            className="form-control"
            placeholder="youremail@example.com"/>

        </label>

        <label className="d-block mb-4">
            <span className='form-label d-block'>Please use this space to let us know any questions you have for the Q&A</span>
            <textarea 
                name='q-and-a-questions'
                className="form-control">
                    
                </textarea>
        
        </label>



            <input name ='webinarcheck' type='checkbox'></input>
            <label className='spaceleft' for='webinarcheck' >
               I will be attending the DGLA Webinar
            </label>

            <br/><br/>



{/*         <label class="d-block mb-4">
          <span class="form-label d-block">Phone number</span>
          <input
            name="phone"
            type="text"
            class="form-control"
            placeholder="###-###-####"
            required
          />
        </label>

        <label class="d-block mb-4">
          <span class="form-label d-block">Subject</span>
          <input
            name="subject"
            type="text"
            class="form-control"
            placeholder="Subject"
            required
          />
        </label>

        <label class="d-block mb-4">
          <span class="form-label d-block">Message</span>
          <textarea
            name="message"
            class="form-control"
            rows="3"
            placeholder="Tell us what you're thinking about..."
            required
          ></textarea>
        </label>
 */}
        <div class="mb-3">
          <button type="submit" class="btn btn-primary px-3 rounded-3">
            Submit
          </button>
        </div>


      </form>
    </div>
  </div>
</div>

      );
    };
    
    export default WebinarForm;
    

    

