import React, { useState } from "react";

const StoreField = (props) => {
    const useNum = props.num + 1;
    const fieldNumber = 'Store ' + useNum + ' Number';
    const fieldLocationStreet = 'Store ' + useNum + ' Street';
    const fieldLocationCity = 'Store ' + useNum + ' City';
    const fieldLocationState = 'Store ' + useNum + ' State';
    const fieldLocationZip = 'Store ' + useNum + ' Zip';

    const fieldOwned = 'Store ' + useNum + ' Owned Since';
    return (
        <div className="storefieldsparent container">
            <h4>DG Store {useNum}</h4>
            <div className="row mb-3" >
                <label for={fieldNumber} className="col-form-label col-sm-4">Store Number:</label>
                <div className="col-sm-8"><input name={fieldNumber} type='text' className="form-control"></input></div>
            </div>
            <div className="row mb-3" >
                <label for={fieldLocationStreet} className="col-form-label col-sm-4">Street Address:</label>
                <div className="col-sm-8"><input name={fieldLocationStreet} type='text' className="form-control"></input></div>
            </div>
            <div className="row mb-3" >
                <label for={fieldLocationCity} className="col-form-label col-sm-4">City:</label>
                <div className="col-sm-8"><input name={fieldLocationCity} type='text' className="form-control"></input></div>
            </div>
            <div className="row mb-3" >
                <label for={fieldLocationState} className="col-form-label col-sm-4">State:</label>
                <div className="col-sm-8"><input name={fieldLocationState} type='text' className="form-control"></input></div>
            </div>
            <div className="row mb-3" >
                <label for={fieldLocationZip} className="col-form-label col-sm-4">Zip:</label>
                <div className="col-sm-8"><input name={fieldLocationZip} type='text' className="form-control"></input></div>
            </div>
            <div className="row mb-3" >
                <label for={fieldOwned} className="col-form-label col-sm-4">Owner since:</label>
                <div className="col-sm-8"><input name={fieldOwned} type='text' className="form-control"></input></div>
            </div>
        </div>
    )
}

const StoreAdder = () => {
    const [inputList, setInputList] = useState([]);
    const [count, setCount] = useState(0);
    const PushField = () => {
        if (count < 4) {
            setInputList(inputList.concat(<StoreField num={count} />));
            setCount(count + 1);
        }
    }
    return (
        <div id='stores-form'>
            <p>For the following section, please input the details of the Dollar General Properties you currently own. If you own more than 4, please attach a file containing the relevant information for your respective stores (Store Number, Location, Owned Since date).</p>
            {inputList}
            <button type='button' onClick={PushField}>Add Store</button>
            <br /><br />
            <p>OR</p>
            <input name='storesfile' type='file' className="file form-control" />
        </div>
    )
}

const IntakeForm = () => {
    return (
        <div className="container intake-div">
            <div className="row mx-0 justify-content-center">
                <div className="col-xl-7 col-lg-9 col-md-11  col-sm-12 form-parent">

                    <form
                        action="https://public.herotofu.com/v1/414702a0-b21a-11ed-aa50-893756e53d16"
                        method="POST"
                        className="w-100 rounded-3 p-4 border"
                        encType="multipart/form-data"

                    >
                        <p className="intake-message">
                            Thank you for interest in joining Dollar General Landlords' Association! In order to understand how we can best serve your needs as a community member, we ask that you fill out the following form.
                        </p>

                        <label className="d-block mb-4">
                            <span className="form-label d-block">Name</span>
                            <input
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder=""
                                required
                            />
                        </label>

                        <label className="d-block mb-4">
                            <span className="form-label d-block">Email</span>
                            <input
                                name="email"
                                type="email"
                                className="form-control"
                                placeholder=""
                                required
                            />
                        </label>

                        <label className="d-block mb-4">
                            <span className="form-label d-block">Phone Number</span>
                            <input
                                name="phone"
                                type="tel"
                                className="form-control"
                                placeholder=""
                                required
                            />
                        </label>

                        <hr />

                        <StoreAdder></StoreAdder>

                        <hr />

                        <label className="d-block mb-4">
                            <span className="form-label  d-block">What are you looking to get out of DGLA?</span>
                            <textarea
                                name="looking-for"
                                className="form-control"
                                rows="3"
                                placeholder=""
                                required
                            ></textarea>
                        </label>

                        <label className="d-block mb-4">
                            <span className="form-label  d-block">Other questions, suggestions, or comments (optional)</span>
                            <textarea
                                name="other-comments-questions"
                                className="form-control"
                                rows="3"
                                placeholder=""
                            ></textarea>
                        </label>



                        <div className="d-block mb-4">
                            <div>
                                <p>                Do you consent to having your contact information shared with other DGLA members?</p>
                                <div className="form-check">
                                    <label className="d-block">

                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            name="consent-to-share-contact"
                                            value="yes"
                                            checked
                                        />
                                        <span className="form-check-label">I do</span>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <label className="form-check">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="consent-to-share-contact"
                                        value="no"
                                    />
                                    <span className="form-check-label">I do not</span>
                                </label>
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="form-check">
                                <label className="d-block">
                                    I'd like to receive news and updates from DGLA via email
                                    <input
                                        name="communications"
                                        value="yes"
                                        type="checkbox"
                                        className="form-check-input"
                                        defaultChecked
                                    />
                                    <span className="form-check-label"></span>
                                </label>
                            </div>
                        </div>



                        <div className="mb-3">
                            <button type="submit" className="btn btn-primary px-3 rounded-3">
                                Submit
                            </button>
                        </div>

                    </form>
                </div>
            </div >
        </div >

    )
}

export default IntakeForm;