import React from "react";

import { NavLink } from "react-router-dom";

import PageTitle from "./components/Pagetitle";
import WebinarForm from "./components/WebinarForm";

import './Webinar.css';
import './Contact.css';


const Webinar = () => {
    
    return (
        <div id='webinar' className="">
            <PageTitle className='no-padding' htext='DGLA Member Webinar' />
            <div className="container">
                <div className="row info-area">

                    <div className="col-lg-6 text-div">
                        <h3 className="webinar-date">Save the Date:<span className="webinar-special"> September 27th, 2023</span></h3>
                        
                        <div className="agenda-info">

                            <ul>
                                <li>Introduction to DGLA and a welcome to our members</li>
                                <li>Walkthrough of member benefits</li>
                                <li>Q&A Session with some of our founding members</li>
                            </ul>
                        </div>
                        
                        <p>As an exclusive offer to our members, we are hosting our first webinar event on <b>September 27th</b>. You can hear from the DGLA team, other members, and industry experts. Sign up  to learn more about our organization, discuss important DG Landlord topics, and get to know the community!</p>



                        <p>If you are a current member and you have completed our intake form, please use this form to sign up to attend the webinar. To become a DGLA member, visit our <NavLink className='landing-links2' to='/Contact'>Contact</NavLink> page.</p>

                    </div>

                    <div className="col-lg-6">
                        <WebinarForm />
                    </div>

                </div>
            </div>


            <br />
            <br />
            <br />


        </div>
    )
}

export default Webinar;