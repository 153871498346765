import React from "react";
import { NavLink } from "react-router-dom";

import './HomeLinkBox.css'


class HomeLinkBox extends React.Component {
    render() {
        const htext = this.props.htext;
        const ptext = this.props.ptext;
        const dest = this.props.dest;
        return (
            <div className="col-lg-3 h100">
                <div className="home-link-box">
                    <div className="center-text">
                    <h6>{htext}</h6>

                    </div>
                    <p>{ptext}</p>

                    <div className="center-text">
                    <NavLink to={dest}><button>Click Here</button></NavLink>

                    </div>
                </div>
            </div>

        );
    }
}

export default HomeLinkBox;