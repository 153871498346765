import React from "react";
import { NavLink } from "react-router-dom";

import './Contact.css';


const IntakeSubmission = () => {
    return(
        <div id='IntakeSubmission' className="container">
            <div className="row submission-parent">
                <div className="col-12 submission-paragraph">
                    <h1>
                        DGLA Intake Form submitted successfully.
                    </h1>
                    <h3>A representative from DGLA will be in touch shortly.</h3>
                    <h3>Click <span className="sub-link"><NavLink className='sub-link' to='/'>here</NavLink></span> to return to the homepage.</h3>
            </div>
            </div>


        </div>
    )
}

export default IntakeSubmission;