import React from "react";
import Warning from './assets/Warningicon.png';


const Selling = () => {
    return (
        <div className="container">

            <div className="row">
                <h3 className="resource-title">ROADMAP: Selling a Dollar General</h3>
            </div>

            <div className="row justify-content-between">
                <h4>Select a commercial real estate...</h4>
                <div className="col-md-5">

                    <div className="border-A">
                        <h5>Brokerage</h5>
                        <p className="big-p">to assist you with listing and marketing the property.</p>
                    </div>

                </div>

                <div className="col-sm-1 d-flex align-items-center gx-0 and-div">
                    <h5 className="text-center">and</h5>
                </div>

                <div className="col-md-5">
                    <div className="border-A">
                        <h5>Attorney</h5>
                        <p className="big-p">to assist you with deed preparation and closing coordination.</p>
                    </div>

                </div>

            </div>

            <div className="row warning-row">
                <div className='col-sm-2 warning-parent'>
                    <img src={Warning} className="library-img" />

                </div>
                <div className="col-sm-10">
                    <p><span className="bolder-p">NOTE:</span> <br />Consider any outstanding maintenance issues that put you in default of the lease terms and will prevent Dollar General from issuing a clean estoppel</p>
                </div>

            </div>

            <div className="row due-dilligence-parent">

                <div className="col-lg-12 due-dilligence">
                    <h4>Start collecting and organizing the <span className="bolder">due diligence documents</span> that the Purchaser is going to ask for:</h4>
                    <hr/>

                    <ul>

                        <li>Original Lease and Lease Amendments</li>
                        <li>Survey</li>
                        <li>Existing Title Insurance Policy</li>
                        <li>Deed</li>
                        <li>Property Tax<span className="non-bolded">, including proof that Dollar General reimburses (if stated that tenant is responsible for reimbursement in the lease)  </span></li>
                        <li>Current Insurance information<span className="non-bolded">, including proof that Dollar General reimburses (if stated that tenant is responsible for reimbursement in the lease)  </span></li>
                        <li>Maintenance and Repair contact list<span className="non-bolded"> including lawncare and snow removal, maintenance log, current warranties </span></li>



                    </ul>




                </div>


            </div>

            <div className="row selling-final-parent">
                <div className="col-lg-12 selling-final">
                <p className="non-bolded">
                    Once a Purchase and Sale Agreement is executed by all parties, order the <span className="bolder">Estoppel/SNDA</span> (if required by the Purchaser) from the tenant. Receiving these documents typically carries no cost to you, and requesting them from Dollar General will expedite and streamline the closing process. Possible compliance issues noted or documents listed that need to be collected from the tenant will take time to resolve, so having these document in hand early on is advantageous to everyone involved. 
                    </p>
                </div>
            </div>

        </div>

    )
}

export default Selling;