import React from "react";
import PageTitle from "./components/Pagetitle";

import './Services.css'

import Constructionbanner from './images/constructionbanner.png'
import Moneybanner from './images/moneybanner.png'
import Phonebanner from './images/phonebanner.jpeg'
import Taxbanner from './images/taxbanner.jpeg'
import Networkingbanner from './images/networkingbanner.png'

const Services = () => {
    return (
        <div id="Services">
            <PageTitle htext='DGLA Benefits' />
            <div className="container">
                <div className="row h-100 larger-text justify-content-center">
                    <p>It’s no secret that commercial real estate is a great investment, but as your portfolio grows it can become challenging to juggle the day-to-day needs of your tenants. Handling maintenance requests, insurance, and tax obligations can be difficult, especially when you have properties in multiple states. DGLA was created to allow the Dollar General Landlord community to work together to alleviate some of this stress so we can get back to the important stuff – closing on new properties and building your portfolio. </p>
                </div>

                <div className="row h-100 justify-content-center">

                    <div className="col-lg-6 servCol">
                        <div className="serviceSection">
                            <img src={Constructionbanner} />
                            <div className="serviceTitle">
                                <h3>M&R Vendor Network</h3>
                            </div>

                            <ul>
                                <li>Roof inspection and repair</li>
                                <li>HVAC preventative maintenance and repair</li>
                                <li>Asphalt maintenance and repair</li>
                                <li>Landscaping</li>
                                <li>M&R comprehensive outsourcing solutions</li>
                            </ul>
                        </div>

                    </div>

                    <div className="col-lg-6 servCol">
                        <div className="serviceSection">
                            <img src={Phonebanner} />
                            <div className="serviceTitle">
                                <h3>Insurance and Tax</h3>
                            </div>
                            <ul>
                                <li>Association purchasing power for insurance services</li>
                                <li>Best practices for coverages in hard to insure areas</li>
                                <li>Property tax administration and factoring</li>
                            </ul>
                        </div>
                    </div>

                    
{/*                     <div className="col-lg-4">
                        <div className="col serviceSection">
                            <img src={Moneybanner} />
                            <div className="serviceTitle">
                                <h3>Optimizing Finances</h3>
                            </div>

                            <ul>
                                <li>Lender syndicate for acquisition (recapitalization) of properties</li>
                                <li>Benchmark of key financial variables</li>
                                <li>Maximizing depreciation</li>
                                <li>1031 Exchange considerations</li>
                                <li>Capital Projects</li>
                            </ul>
                        </div>
                    </div> */}

                    <div className="col-lg-6 servCol">
                        <div className="serviceSection">
                            <img src={Taxbanner} />
                            <div className="serviceTitle">
                                <h3>Lease Administration</h3>
                            </div>

                            <ul>
                                <li>Lease negotiation</li>
                                <li>How likely is DG to renew?</li>
                                <li>Dark store re-tenanting</li>
                                <li>Navigating Dollar General's system and communicating with DG</li>
                            </ul>
                        </div>
                    </div>


                    <div className="col-lg-6 servCol">
                        <div className="serviceSection">
                            <img src={Networkingbanner} />
                            <div className="serviceTitle">
                                <h3>Networking Opportunities with Fellow Landlords</h3>
                            </div>

                            <ul>
                                <li>Events and opportunities to meet other owners</li>
                                <li>Share ideas and create relationships</li>
                            </ul>
                        </div>
                    </div>

                </div>




            </div>
        </div>
    )
}

export default Services