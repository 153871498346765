const TicketingReductions = () =>{
    return(
        <div className="container" id='TicketingReductions'>

            <div className="row">
                <h3 className="resource-title">Maintenance Ticketing and Rent Reductions</h3>
            </div>


            <div>
                <p>
                As the Landlord, there are certain maintenance and repair items which are deemed to be your responsibility to remedy. Those items are determined by the language in your Lease Agreement and can vary in each lease.  

                </p>
            </div> 

            <div>
                <h4>Receiving a Ticket</h4>
                <p>
               Dollar General will notify the Landlord when maintenance items arise by submitting a ticket both through <span className="bolder">email</span> and by <span className="bolder">USPS certified mail</span>. The Landlord must then make the requested repairs in a reasonable time frame, which is <span className="bolder">within 30 days</span>. It is highly recommended, upon receipt of the ticket, to reach out to the contact person named on the ticket and <span className="bolder">communicate an action plan</span>. This will make the tenant aware that you are going to be completing the repairs so they do not send their own vendor out and charge you for it. 

                </p>
            </div>

            <div>

                <h4>Tenant Follow-up</h4>

                <p>
                Each week following the initial ticket, the tenant will send reminders by email and certified mail. The second and third notices will be simple reminders and will restate everything contained in the initial ticket. If the Landlord fails to respond to the notices, or to complete repairs within the allotted timeframe, they will receive a <span className="bolder">Proceeding Notice</span>, which will state the Landlord is in default of the lease, and that <span className="fst-italic">the tenant is completing the repairs as allowed under the Lease</span>.  
 
                </p>
            </div>

            <div>

                <h4>Emergency Maintenance</h4>

                <p>
                In some cases <span className="bolder">deemed as emergencies</span>, <span className="fst-italic">the tenant will only notify the landlord of their intent to complete repairs</span>. This generally happens when a store is <span className="bolder">without working HVAC or toilets</span>. For the safety of their customers and employees, Dollar General will move forward with emergency repairs, and notify you by sending an emergency letter via certified mail. In these cases, that letter is the only notice you will receive. 

                </p>
            </div>

            <div>

                <h4>Closing a Ticket</h4>

                <p>
                If the Landlord completes the repairs within that time frame, they can close out the ticket by sending notice to the tenant. This can be done in two ways: 

                </p>

                <ol className="larger-list">
                    <li>
                    <span className="non-bolded">Emailing the Lease Coordinator directly (Their contact info will be on all notices)</span> 
                    </li>

                    <li>
                        <span className="non-bolded">                    Submitting a resolution form <a href='http://www2.dollargeneral.com/Corporate-Information/pages/lease-compliance.aspx' target="_blank" className="text-decoration-underline">here</a>  </span>
                    </li>
                </ol>
            </div>

            <div className="row broker-parent">
            <div className="RentReductions col-12 broker-rep">
                <h4>Rent Reductions</h4>
                <p>
                In the event the landlord is unable to complete their repairs in the allotted time frame, Dollar General will exercise their rights under the Lease Agreement, and <span className="fst-italic">move forward with their own vendor to complete repairs</span>. This will incur a <span className="bolder">rent reduction</span>.  

                </p>

                                <p>
                When a ticket comes up at your store, you as the landlord have 30 days to resolve that issue. Typically this would involve finding a vendor and paying them yourself to go out and complete the repairs. However, what happens when you aren’t able to find the right person for the job?  

                </p>

                                <p>
                The good news is Dollar General themselves has the right to <span className="bolder">find their own vendor</span>, so the issue will be fixed. The bad news is <span className="bolder">the cost of that vendor will then come out the rent that your store pays you every month</span>.  

                </p>

                <div>
                    <hr></hr>
                <p className="fst-italic">
                Let’s say you own a Dollar General that pays you $5,000 a month in rent. The store tell you that there’s a plumbing issue, and that you contractually have 30 days to fix it. For whatever reason, you are not able to find a plumber within that time. Dollar General then finds someone to do the repairs, and the cost is $1,000. They will deduct that $1,000 from your rent, meaning that this month you will only receive $4,000 from your store.  

                </p>

                <hr></hr>
            </div>
            <div>
                
            </div>
                <p className="bolder">The vendor that Dollar General chooses is typically not the cheapest, so it is in your best interests to find a vendor to complete the repairs within the 30-day period.  
    </p>
            </div>
            </div>







        </div>
    )
}

export default TicketingReductions;