import React from "react";

import './PageTitle.css'

class PageTitle extends React.Component {
    render() {
        const htext = this.props.htext;

        return (
            <div className="page-title container">
                <div className="title-text row">
                    <h1>{htext}</h1>
                </div>
            </div>

        );
    }
}

export default PageTitle;