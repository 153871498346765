import React from "react";


import "./nav.css";
import MenuLink from './MenuLink';
import Hamburger from './images/hamburger.png';
import HeaderLogo from './images/newlogoblack.png';







class Header extends React.Component {
    render() {

        const toggleMenu = () => {
            let menu = document.getElementById('collapsible');
            let hamburger = document.getElementById('hamburgerDiv')

            if (menu.style.display === 'none') {
                console.log('nice');
                menu.style.display = 'block';
                hamburger.style.paddingBottom = '0px';

            } else {
                menu.style.display = 'none';
                hamburger.style.paddingBottom = '20px';
            }


        }

        let ScrollHeight = document.documentElement.dataset.scroll = window.scrollY;




        return (




            <div id="HeaderDiv" className="box">



                <ul className="navUl">
                    <li><img src={HeaderLogo} alt="DG Landlord Association logo" className="HeaderLogo" 
                    /></li>
                    <li><MenuLink dest='Home' linktext='Home' /></li>
                    <li><MenuLink dest='Services' linktext='DGLA Benefits' /></li>
                    <li><MenuLink dest='Library' linktext='Resources' /></li>
                    {/* <li><MenuLink dest='About' linktext='About Us' /></li> */}
                    <li><MenuLink dest='Contact' linktext='Join DGLA' /></li>

                </ul>

                <div id="mobileUl">
                    <div className="mobile-header-logo">
                        <ul>
                            <li>
                            <img src={HeaderLogo} alt="DG Landlord Association logo" className="HeaderLogo" />
                            </li>
                        </ul>
                    </div>

                    <div id="hamburgerDiv">
                        <div style={{
                            textAlign: `center`
                        }}>
                            <img
                                src={Hamburger}
                                alt='menu button'
                                style={{ width: `40px` }}
                                className='navBurger'
                                onClick={toggleMenu}
                            />
                        </div>
                    </div>

                    <ul id="collapsible" style={{display: "none"}}>
                        <li></li>
                        <li><MenuLink dest='Home' linktext='Home' /></li>
                        {/* <li><MenuLink dest='About' linktext='About Us' /></li> */}
                        
                        <li><MenuLink dest='Services' linktext='DGLA Benefits' /></li>
                        <li><MenuLink dest='Library' linktext='Resources' /></li>

                        <li><MenuLink dest='Contact' linktext='Contact' /></li>
                    </ul>
                </div>

                {/*                 <div className="slogan">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                            <h4 className="italic">"Effective Solutions for Driven Landlords"</h4>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>



        );
    }
}

export default Header;
