import React, {useState} from "react";
import { NavLink } from "react-router-dom";
import './Library.css';

import Purchasing from "./Purchasing";
import Selling from "./Selling";
import LibAbout from "./LibAbout";
import Cam from "./Cam";
import ChangeOfOwnership from "./ChangeOfOwnership";
import MaintenanceReimbursements from "./MaintenanceReimbursements";
import TicketingReductions from "./TicketingReductions";



const Library = () => {

    const [currentPage, setPage] = useState(<Purchasing/>);


    const TocLink = (props) => {
        let refPage = props.output;
        let linkText = props.desc;
        const newHtml = refPage;
    
        return (
            <li className="button">
                <button onClick={()=> setPage(refPage)}>{linkText}</button>
            </li>
        )
    }


    return (
        <div id='Library'>

            <div className="container library-container">

                <div className="row library-parent-row">

                    <div className="col-lg-4 toc-col">
                                        <h4>Member Resource Library</h4>
                                        <hr></hr>

                        <TocLink output={(<LibAbout/>)} desc='About this library' />
                        <TocLink output={(<Purchasing/>)} desc='Purchasing a Dollar General' />
                        <TocLink output={(<Selling/>)} desc='Selling a Dollar General' />
                        <TocLink output={(<Cam/>)} desc='Common Area Maintenance (CAM)' />
                        <TocLink output= {(<TicketingReductions/>)} desc='Maintenance Ticketing and Rent Reductions' />
                        <TocLink output= {(<MaintenanceReimbursements/>)} desc='Maintenance Reimbursements' />


                    </div>

                    <div className="col-lg-8 library-contents">
                        
                        {currentPage}

                    </div>



                </div>


            </div>


        </div>
    );
}

export default Library;