import React, {useState} from "react";

const FORM_ENDPOINT = "https://public.herotofu.com/v1/693cbf00-2491-11ed-ba49-1b632df649d8"

const ContactForm = () => {
    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = () => {
        setTimeout(() => {
          setSubmitted(true);
        }, 100);
      };

      if (submitted) {
        return (
          <>
            <h2>Thank you!</h2>
            <div>We'll be in touch soon.</div>
          </>
        );
      }

      return (
        <div class="container">
  <div class="row mx-0 justify-content-center">
    <div class="col-12">
      <form
        method="POST"
        class="w-100 rounded-1 p-4 border bg-white"
        action={FORM_ENDPOINT}
      >
        <label class="d-block mb-4">
          <span class="form-label d-block">Your name</span>
          <input
            name="firstname"
            type="text"
            class="form-control"
            placeholder="First"
          />

<input
            name="lastname"
            type="text"
            class="form-control"
            placeholder="Last"
          />
        </label>

        <label class="d-block mb-4">
          <span class="form-label d-block">Email Address</span>
          <input
            name="email"
            type="email"
            class="form-control"
            placeholder="youremail@example.com"
            required
          />
        </label>

        <label class="d-block mb-4">
          <span class="form-label d-block">Phone Number</span>
          <input
            name="phone"
            type="text"
            class="form-control"
            placeholder="###-###-####"
            required
          />
        </label>

        <label class='d-block mb-4'>
          <span class='form-label d-block'>Where did you hear about us?</span>
          <select 
            id='sourcedropdown'
            name='heardfrom'
            className="form-control">
              <option value=''>Choose one:</option>
              <option value='mail'>Mail</option>
              <option value='email'>Email</option>
              <option value='online'>Found online</option>
              <option value='word-of-mouth'>Word of mouth</option>
              <option value='other'>Other</option>

            </select>
        </label>

{/*         <label class="d-block mb-4">
          <span class="form-label d-block">Phone number</span>
          <input
            name="phone"
            type="text"
            class="form-control"
            placeholder="###-###-####"
            required
          />
        </label>

        <label class="d-block mb-4">
          <span class="form-label d-block">Subject</span>
          <input
            name="subject"
            type="text"
            class="form-control"
            placeholder="Subject"
            required
          />
        </label>

        <label class="d-block mb-4">
          <span class="form-label d-block">Message</span>
          <textarea
            name="message"
            class="form-control"
            rows="3"
            placeholder="Tell us what you're thinking about..."
            required
          ></textarea>
        </label>
 */}
        <div class="mb-3">
          <button type="submit" class="btn btn-primary px-3 rounded-3">
            Contact Us
          </button>
        </div>


      </form>
    </div>
  </div>
</div>

      );
    };
    
    export default ContactForm;
    

    

