import React from "react";

import './About.css';
import PageTitle from "./components/Pagetitle";


import MailIcon from './images/email-Icon.png'
import LinkedIn from './images/linkedin.png'
import Chloe from './images/team/chloe-circle.png'
import Austin from './images/team/austin-circle.png'
import Cory from './images/team/cory-circle.png'
import Travis from './images/team/travis-circle.png'


const FakeBio = `
Chloe has extensive experience managing maintenance, insurance, and taxes for commercial real estate properties. She has onboarded close to 100 Dollar General properties, managed $1.2 million in tax payments & tenant reimbursements in 2021, and in that same year oversaw the closure of 200+ maintenance tickets. 
`

const Staff = (props) => {
    return (
        <div className="container staff-section">
            <div className="row bio-row">
                <div className="col-lg-3 pic-col">
                    <img className="team-member-pic" src={props.img} />
                </div>
                <div className="col-sm-9">
                    <h3 className="team-member-name">{props.name}</h3>
                    <h5 className="team-member-title">{props.jobtitle}</h5>
                    <p className="bio-text">{props.bio}</p>
                    <p className="bio-email bio-text">{props.email}</p>


                </div>
            </div>
            

        </div>
    )
}


const About = () => {
    return (
        <div id='About'>
                        <PageTitle htext='About Us'/>


               <div className="container">
                    <div className="row mission-section">
                        <p>We are commited to helping independent Dollar General store property owners achieve improved financial results and reduced complexity of ownership as a Landlord.</p>
                        <p>Our team of skilled property management and sales experts are here to help Dollar General owners succeed. Learn more about our team below:</p>
                    
                    </div>
                    <div className="row"></div>
                </div> 
            <Staff img={Chloe} name='Chloe Guhl' jobtitle='Director of Business Operations' bio={FakeBio} email='cguhl@battlemonument.com' />

            <hr/>

            <Staff img={Austin} name='Austin Long' jobtitle='Assistant Controller'
            bio="Austin has a broad range of experience in the commercial real estate industry. He has worked in appraisal, property and project management, and accounting on both the private and government level. He is a graduate of the VCU School of Business with a BS in Real Estate, which focuses on investment analysis and financial reporting. Outside of real estate, he enjoys playing soccer, cooking and running, as well as being an avid Manchester United supporter."  email='along@battlemonument.com'
            />
            <hr/>

            <Staff img={Cory} name='Cory Brumagin' jobtitle='Acquisition Anaylist'
             bio="Cory has an abundance of experience in reviewing Dollar General property deals, visiting and evaluating viable properties and preparing proposals. He has been directly involved in the purchase of almost 20 Dollar General properties and has analyzed over 1500 properties in virtually every state. Cory is a graduate of the University of South Carolina, where he earned his B.S. He is currently pursuing an MBA from William & Mary's Raymond A. Mason School of Business. Specializing in acquisitions, Cory has experience with analyzing, assessing, inspecting, and purchasing properties." email='cbrumagin@battlemonument.com'
             />
            <hr/>

            <Staff img={Travis} name='Travis Wilson' jobtitle='Repairs and Maintenance' 
            bio="Travis has wide-ranging experience in overseeing maintenance and repairs, work order management, and maintaining positive, productive relationships with tenants, vendors and state agencies. In 2022, he has overseen the completion of 275+ work orders, with a combined annual spend of over $500,000, and an average of $5,000 in maintenance reimbursements quarterly. He is establishing an ever-growing network of over 150+ trusted, reliable contractors of varied specialties to address work orders in a timely fashion."
            email='twilson@battlemonument.com'
            />
        
{/*             <div className="container bmg-info">
                    <div className="row bio-text">
                        <h2>A note from our Sponsor, Battle Monument Group</h2>
                        <p>We are a private real estate fund focused on providing stable fixed income to our investors. Our 360+ investors consist largely of military veterans and industry leading professionals. We are proud to have investors in four different funds, with a reinvestment rate by our investors in excess of 70%.</p>
                        <p>Our product focus is on single-tenant commercial properties, with investment-grade tenants and a long-term lease. We specialize in long-term investment in defensive assets, with emphasis on discount retailers (Dollar Stores) in secondary and tertiary markets.</p>
                        <p>Click <a className='bmg-link' href='http://battlemonument.com' target='_blank'>here</a> to visit the Battle Monument Homepage</p>
                    </div>
                </div>  */}
        
        </div>
    )
}

export default About