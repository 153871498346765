import { NavLink } from "react-router-dom";
import HomeLinkBox from "./components/HomeLinkBox";


import './Home.css'
import './Landing.css'
import './WebinarAnnouncement.css'

import DGpic from './images/dgstore.png'
import Golf from './images/golf.jpeg'


const WebinarAnnouncement = () => {
    return (
        /*         <div className="row save-the-date g-5">
        
        
                    <div className="col-lg-5">
                        <div className="midparent">
                        <h4 className="">Save the Date:</h4>
                        <h5><span className="special-text">Aug. 14,</span> 2023</h5>
                        </div>
        
                    </div>
        
        
                    <div className="col-lg-7">
                        <div className="midparent">
                             <h6>Announcing our first <span className="special-text">Webinar</span> for DGLA members. Register to attend and submit questions for the Q&A by clicking <NavLink className='landing-links' to='/Webinar'>here</NavLink>.</h6>
                        </div>
                    </div>
        
        
        
                </div> */
        <div className="container">
            





            <div className="row info-boxes overflow-hidden">


                <div className="col-lg-4 midparent2">
                    <div className="">
                    <h1>Join Us Today</h1>
                    <p>Interested in taking advantage of our offerings? <NavLink className='landing-links' to='/Contact'>Sign up</NavLink>  today for no fee.</p>
                
                    </div>
                   </div>


                <div className="col-lg-4 midparent2">
                    <div>
                    <h1>Resource Library</h1>
                    <p>DGLA property management specialists provide regular articles and resources to support owners. <NavLink className='landing-links' to='/Library'>Click Here</NavLink>.
                    </p>

                    </div>
                </div>

                <div className="col-lg-4 midparent2">
                    <div>
                    <h1>Benefits</h1>
                    <p><NavLink className='landing-links' to='/Services'>Click Here</NavLink> to Learn how we can support you as a Landlord and best serve your stores. .
                    </p>
                    </div>

                </div>

            </div>

        </div>


    )
}

const Home = () => {
    return (
        <div id="HomeDiv">
            <div className="i">


                {/*                 <div className="row introRow">


                    <div className="col-sm-6">

                        <div className='about-mission'>
                            <h1>About Us:</h1>
                            <p>An organization of landlords who each own 10 or more individual Dollar General locations looking to get the best results from each</p>
                        </div>

                        <div className='about-mission'>
                            <h1>Our Mission: </h1>
                            <p>
                                We are commited to helping independent property owners with 10 or more Dollar General stores to achieve improved financial results and reduced complexity of ownership as a Landlord.
                            </p>
                        </div>

                    </div>

                    <div className="col-sm-6">
                        <img className='dgPic' src={DGpic} alt='a Dollar General Storefront' />
                    </div>

                </div>

                <div className="row">
                    <div className="col-sm-2">

                    </div>

                    <div className="col-sm-4">
                        <img className='golfPic' src={Golf} alt='Golf ball on a tee' />
                    </div>

                    <div className="col-sm-4">
                        <h2>Upcoming Events</h2>
                        <ul>
                            <li>Annual Golf Outing</li>
                            <li>Virtual Vendor Conference Room</li>
                            <li>Virtual Supplier Conference Room</li>
                        </ul>
                    </div>


                    <div className="col-sm-2"></div>
                </div> */}

                <div className='container'>
                    <div className='row homepage-h'>
                        <div>
                            <div className="container big-img">
                                <div className='row'>
                                    <div className='col-12'>
                                        <h1 className="big-img-text">Effective Solutions for Driven Landlords.</h1>
                                        <h3>We are committed to developing a community of independent Dollar General property owners aiming to achieve reduced complexity of ownership as a Landlord.
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {WebinarAnnouncement()}
{/* 
                                        <div className="row">
                        <div className="save-the-date2 col-lg-5 container g-5">
                            <div className="row info-boxes overflow-hidden">



                                <div className="col-md-12 midparent p-3">
                                    <h1>Join Us Today</h1>
                                    <p>Interested in taking advantage of our offerings? Sign up today for no fee.</p>
                                    <button className="mt-auto"><NavLink to='/Contact'>Click Here</NavLink></button>
                                </div>


                                <div className="col-md-12 midparent p-3">
                                    <h1>Read Our Blog</h1>
                                    <p>DGLA property management specialists provide regular articles and resources to support owners.

                                    </p>
                                    <button className="mt-auto"><NavLink to='/Blog'>Click Here</NavLink></button>
                                </div>

                            </div>

                        </div>
                    </div> */}

{/*                     <div className="row info-boxes overflow-hidden">

                        <div className="col-md-4 p-3">
                            <h1>Benefits</h1>
                            <p>Learn how we can support you as a Landlord and best serve your stores.</p>
                            <button className="mt-auto"><NavLink to='/Services'>Click Here</NavLink></button>
                        </div>

                        <div className="col-md-4 p-3">
                            <h1>Join Us Today</h1>
                            <p>Interested in taking advantage of our offerings? Sign up today for no fee.</p>
                            <button className="mt-auto"><NavLink to='/Contact'>Click Here</NavLink></button>
                        </div>


                        <div className="col-md-4 p-3">
                            <h1>Read Our Blog</h1>
                            <p>DGLA property management specialists provide regular articles and resources to support owners.

                            </p>
                            <button className="mt-auto"><NavLink to='/Blog'>Click Here</NavLink></button>
                        </div>

                    </div> */}



                    <div className="row homepage-q bg">

                        <div className="col-md-7">
                        <p>"As an owner of 12 DG properties, I had two daily headaches. One came from constant maintenance notices. The other came from keeping track of local property taxes and reimbursments. DG Landlords' Association helped take the headache out of both areas."</p>
                        <p className="terry-j">- Terry J</p>
                        </div>

                        <div className="col-md-5 dg-bottom-pic">
                        <img src={DGpic} />
                    </div>
                    <br/><br/><br/><br/><br/>
                    </div>


                    {/*                     <div className='row homepage-p'>
                        <h1>About Us</h1>
                        <p>An organization of landlords who each own 10 or more individual Dollar General locations looking to get the best results from each</p>

                        <div className='button-div'>
                            <NavLink to='/About'>
                                <button>Meet our team</button>
                            </NavLink>                        
                        </div>
                    </div> */}

                    {/*                     <div className='row homepage-p'>
                        <h1>Our Mission</h1>
                        <p>
                            We are commited to helping independent property owners with 10 or more Dollar General stores to achieve improved financial results and reduced complexity of ownership as a Landlord.
                        </p>
                        <div className='button-div'>
                            <NavLink to='/Services'>
                                <button>Learn more about what we do</button>
                            </NavLink>
                        </div>
                    </div> */}








                </div>


            </div>


        </div>

    )
}

export default Home;