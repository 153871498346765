/*
    {
        id: '',
        title: '',
        date: '',
        content: (<div><p></p></div>)

    },
*/

const Posts = [

    {
        id: '1',
        title: 'Why Dollar General?',
        date: '11/08/22',
        content: (<div>

            <p>	Economic recession, Covid-19, real estate volatility - if there’s one lesson to take away from the 21st century so far, it’s that uncertainty is a core characteristic of financial markets. Whether you are managing money for clients or looking to build your own personal wealth, it’s clear that stable, low-risk investments are a necessity to long-lasting financial security.</p>

            <p>	If you are reading this, you are probably somewhat familiar with the commercial real estate investment world, and you know that CRE is a great place to put your money to work. Specifically, discount retailers like Dollar General (DG) make for a wise, defensive addition to your portfolio with minimal risk to investors. But what makes this niche particularly suitable to those looking to weather any upheaval the markets may present?</p>
            <div className="readmore">
            <p>	The company that would become Dollar General got its start in Kentucky in the late 1930s, when its founder, J.L. Turner, decided to open a business using lessons he learned living through the Great Depression. In the over 80 years that followed, this model of discount retailer has proved itself through countless ups and downs, and similar stores have continued to pop up all over the country.  Retailers like DG provide a crucial service to communities, one that customers rely on during times of economic downturn as well as prosperity.  In some rural parts of the country, Dollar General is one of the only stores that provide essential goods such as groceries, hygiene products, and basic medical supplies. Arguably, these stores perform even better during times of recession, as they remain affordable for those looking to cut costs during trying times. </p>

            <p>	Longevity of assets is also a key variable to consider in selecting commercial real estate investments for your portfolio. You can expect to enter into lease terms of 5 years or more, making the headache of reviewing and negotiating leases an infrequent obligation. There are many advantages to having a large corporate tenant like DG. As a landlord, rent from your tenant is your primary source of income, and DG is required to pay you the same amount of rent each cycle regardless of the store’s performance, or greater economic fluctuations overall. When you add a DG location to your portfolio, you are guaranteed a stable, recurring return for years to come.</p>

            <p>	While a rookie may only look at property values, wise investors look at the above metrics to predict performance and returns in the long run, maximizing their likelihood of staying on top despite market uncertainty. But, if you’re reading this, you probably already know that!</p>
            </div>


        </div>)

    }








]

export default Posts;