import React from "react";

const Cam = () => {
    return (
        <div className="container" id='Cam'>
            <div className="row">
                <h3 className="resource-title">Common Area Maintenance (CAM)</h3>
            </div>

            <div className="row due-dilligence-parent">
                <div className="col-12 due-dilligence">
                    <h4><span className="bolder">Common Area Maintenance</span> (<span className="bolder">CAM</span>) is an additional tenant payment, made on top of the normal base rent, to help offset the expenses incurred by the Landlord for some common, recurring maintenance items.</h4>
                    <ul>
                        <li><span className="non-bolded">Specific items included may vary from lease to lease</span></li>
                        <li><span className="non-bolded">generally established under the lease section entitled </span>Parking Lot Maintenance</li>
                        <li><span className="non-bolded">May include lighting, cleaning, security, snow removal, striping, patching and repairs</span></li>
                    </ul>
               
                </div>
            </div>

            <div className="row">
                <h4>There are two different methodologies for determining payment amounts:</h4>
            </div>

            <div className="row broker-parent">
                <div className="col-12 broker-rep">
                    <h4><span className="bolder">Type 1</span> - Sole Reimbursement</h4>
                    <ul>
                        <li>Regardless of how much money is spent by the Landlord on maintaining the listed CAM items, the tenant will only pay the exact amounts established by the Lease Agreement.</li>
                        <li>Lease language should include the following: <span className="italic">“Lessee agrees to pay Lessor, as Lessee's <span className="bolder">sole reimbursement</span> to Lessor for its actual cost of care and maintenance on the parking lot”</span></li>
                        <li>You will not be able to recover any of these expenses beyond what the Lease allows.</li>
                        <li>This also means you do not need to submit anything to the tenant at the beginning of each year, like you would with a reconciliation property.</li>
                    </ul>
                </div>
            </div>

            <div className="row broker-parent">
                <div className="col-12 broker-rep">
                    <h4><span className="bolder">Type 2</span> - Reconciliation</h4>
                    <ul>
                        <li>Requires a bit more ground work, but has the benefit of potentially enabling the Landlord to recover more of their expenses each year.</li>
                        <li>Requires the Landlord to submit an accounting of the total amounts spent on their recoverable expenses each year. The total amount spent on these recoverable expenses will then need to be compared against the annual CAM payments the tenant has already made.</li>
                        <li>If the <span className="bolder">landlord has spent more</span> than the tenant has paid, the tenant is then required to reimburse the landlord for the difference.</li>
                        <li>However, if the <span className="bolder">tenant has paid more</span> than the landlord spent, the landlord will then be required to reimburse the tenant for the difference.  </li>
                    </ul>
                    <hr></hr>
                    <p className="italic">EXAMPLE: Let’s say tenant pays the landlord $3,600 annually ($300/mo) for their 	share of CAM. As the landlord, you spent $4,000 maintaining the landscaping and parking lot in that same year. The tenant will then owe landlord the difference of $400. If the numbers are switched, with landlord spending $3,600 and tenant paying $4,000 – the Landlord will then need to pay that $400 difference to the tenant.  </p>

                    <hr></hr>

                    <h4>Accounting for Reconciliation</h4>
                    <p>Most reconciliation leases will require the Landlord to submit the accounting of their recoverable expenses <span className="bolder">within 60 days of the beginning of the year</span>, or by March 1st. To do this, the landlord must send the following: </p>
                    <ol>
                        <li><span className="non-bolded">An invoice clearly showing the amounts owed by Tenant or Landlord </span></li>
                        <li><span className="non-bolded">Copies of all invoices from vendors that completed work on CAM areas  </span></li>
                        <li><span className="non-bolded">Copies of all payments made to vendors, showing invoices were paid</span></li>
                        <p>Once those items are together, it can be submitted to <span className="bolder">ralease@dollargeneral.com</span>. </p>
                    </ol>

                </div>
            </div>

        </div>
    );
}

export default Cam;